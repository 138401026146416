<template>
  <div class="login-wrapper" v-if="!isLoggedIn">
    <div class="login-header">
      <div class="logo-container">
        <img class="logo" src="@/assets/hsl_vaaka_sininen.svg" alt="HSL logo" />
      </div>
      <ul class="list--nostyle local">
        <li>
          <button
            class="btn"
            :class="selectedLocale === 'fi' ? 'btn-primary' : ''"
            @click="selectLocale('fi')"
          >
            FI
          </button>
        </li>
        <!-- <li><button class="btn">SV</button></li> -->
        <li>
          <button
            class="btn"
            :class="selectedLocale === 'en' ? 'btn-primary' : ''"
            @click="selectLocale('en')"
          >
            EN
          </button>
        </li>
      </ul>
    </div>
    <div class="login-container">
      <div class="login-card">
        <div class="login-card-header">
          <h1>{{ $t("login.create_account") }}</h1>
          <span
            >{{ $t("common.or") }}
            <router-link to="/" style="font-weight: bold">{{
              $t("common.login")
            }}</router-link></span
          >
        </div>
        <form class="create-account" @submit="submitForm" novalidate>
          <h5 class="form-header">{{ $t("login.contact") }}</h5>
          <div class="double">
            <div>
              <label for="firstName">{{ $t("login.first_name") }}</label>
              <b-form-input
                id="firstName"
                v-model="data.firstName"
                required
              ></b-form-input>
            </div>
            <div>
              <label for="lastName">{{ $t("login.last_name") }}</label>
              <b-form-input
                id="lastName"
                v-model="data.lastName"
                required
              ></b-form-input>
            </div>
          </div>
          <label for="email">{{ $t("login.email") }}</label>
          <b-form-input
            id="email"
            type="email"
            v-model="data.email"
            required
          ></b-form-input>
          <label for="phone">{{ $t("login.phone") }}</label>
          <b-form-input
            id="phone"
            type="tel"
            v-model="data.phone"
            required
          ></b-form-input>
          <label for="password">{{ $t("login.password") }}</label>
          <b-form-input
            type="password"
            id="password"
            v-model="data.password"
            required
          ></b-form-input>
          <label for="password-confirmation">{{
            $t("login.password_confirmation")
          }}</label>
          <b-form-input
            type="password"
            id="password-confirmation"
            v-model="data.passwordRepeated"
            required
          ></b-form-input>
          <p
            class="login-terms"
            v-html="
              $t('common.accept_terms_and_privacy', {
                terms: `<a href='#terms'>${$t('common.terms')}</a>`,
                privacy: `<a href='#priva'>${$t('common.privacy')}</a>`,
              })
            "
          ></p>
          <b-alert show variant="danger" v-if="errors && errors.length">
            <ul class="list--nostyle">
              <li v-for="(error, idx) in errors" :key="idx">
                {{ error }}
              </li>
            </ul>
          </b-alert>
          <button class="button button--filled-blue" type="submit">
            {{ $t("common.continue") }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { createUser } from "../api/userController";
const initData = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  password: "",
  passwordRepeated: "",
  invitationHash: "",
};

export default {
  name: "Register",
  metaInfo() {
    return {
      title: this.$t("common.register"),
    };
  },
  data() {
    return {
      login: true,
      data: { ...initData },
      errors: [],
    };
  },
  methods: {
    selectLocale(locale) {
      this.$store.commit("SET_LOCALE", locale);
    },
    validForm() {
      this.errors;
      const keys = Object.keys(this.data);
      const filled = keys.every((key) => this.data[key]);
      const passwordsMatch = this.data.password === this.data.passwordRepeated;
      !filled && this.errors.push(this.$t("common.fill_all_fields"));
      !passwordsMatch &&
        this.errors.push(this.$t("common.passwords_dont_match"));

      return filled && passwordsMatch;
    },
    async submitForm(e) {
      e.preventDefault();
      this.errors = [];
      if (this.validForm()) {
        const response = await createUser(this.data);
        if (response.status === 200) {
          return this.$router.push("/");
        }
      }
    },
    redirect() {
      if (this.isLoggedIn) {
        return this.$router.push("/");
      }
    },
    setData() {
      this.data.invitationHash = this.$route.params.hash;
      this.data.email = this.$route.params.email;
    },
  },
  computed: {
    selectedLocale() {
      return this.$i18n.locale;
    },
    ...mapGetters(["oidcIsAuthenticated"]),
    isLoggedIn() {
      return this.oidcIsAuthenticated;
    },
  },
  mounted() {
    this.redirect();
    this.setData();
  },
};
</script>
<style lang="scss">
@import "../styles/style.scss";

.login-wrapper {
  background: #e5e5e5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-header {
  width: 100%;
  padding: 30px;
  min-height: 90px;
  display: flex;
  justify-content: space-between;
  .logo {
    height: 50px;
    width: auto;
  }
  .local {
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;

    .btn {
      padding: 0.1em 0.4em;
      font-weight: bold;
      color: #007ac9;

      &.btn-primary {
        color: #fff;
      }
    }
  }
}
.login-container {
  max-width: 920px;
}
.login-card {
  background: #fff;
  border-radius: 4px;
  padding: 2.5rem 2rem;

  &-header {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #ddd;
    h1 {
      font-size: 1.85rem;
    }
  }
}

form.create-account {
  .double {
    display: flex;
    gap: 1rem;
    & > * {
      width: 50%;
    }
    @include media-breakpoint-down(xs) {
      flex-direction: column;
      gap: 0;
      & > * {
        width: 100%;
      }
    }
  }

  label {
    font-weight: 500;
    margin-bottom: 0;
    &::after {
      content: "*";
      color: red;
      margin-left: 5px;
    }
  }
  input {
    margin-bottom: 1rem;
  }

  .login-terms {
    margin-top: 2rem;
  }

  .form-header {
    margin-top: 0;
    margin-bottom: 2rem;
    font-size: 1.25rem;
  }
}
</style>
